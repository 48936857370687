import React, { useState, useEffect, useMemo } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import parse, { domToReact } from 'html-react-parser';
import Box from '@material-ui/core/Box';

import NewsImage from '../assets/image/NEW_welcome_2.jpg';

let styles = {
  title: {
    color: '#373989',
    fontSize: 60,
    fontFamily: 'FuturaLT',
    marginLeft: '-45%',
    marginTop: '10%',
  },
  title2: {
    color: '#373989',
    fontSize: 45,
    fontFamily: 'FuturaLT',
    marginLeft: '-45%',
    marginTop: '9%',
  },
  content: {
    color: '#373989',
    fontSize: 32,
    fontFamily: 'FuturaLT',
    marginLeft: '-45%',
    marginTop: '4%',
    // backgroundColor: 'red',
    height: '53vh',
    width: '130%'
  },
  subContent: {
    color: '#373989',
    fontSize: 32,
    fontFamily: 'FuturaLT',
    marginLeft: '-45%',
    marginTop: '4%',
    justifyContent: 'flex-end',
  },
  image: {
    width: '100%',
    height: '100%',
    borderRadius: '50%',
  },
  publishUser: {
    color: '#373989',
    fontSize: 27,
    fontFamily: 'FuturaLT',
    marginLeft: '11%',
    marginTop: '15%'
  },
  publishAt: {
    color: '#373989',
    fontSize: 27,
    fontFamily: 'FuturaLT',
    marginLeft: '11%',
    marginTop: '5%'
  },
  propsBox: {
    borderColor: '#494b94',
    m: 1,
    border: 8,
    style: { width: '28vw', height: '28vw', margin: '-9%' },
  },
  propsDot: {
    bgcolor: '#c5cdff',
    m: 1,
    border: 0,
    style: { width: '1.3vw', height: '1.3vw', marginLeft: '2vw' },
  },
  selectionnedDot: {
    bgcolor: '#ff5f63',
    m: 1,
    border: 0,
    style: { width: '1.3vw', height: '1.3vw', marginLeft: '2vw' },
  }
}

const News = ({ data, getProgress }) => {
  const [news] = useState(data)
  const [item, setItem] = useState(0)

  useEffect(() => {
    if (!news || !news.length)
      return
    if (getProgress() / (100 / news.length) >= item + 1 && item + 1 < news.length)
      setItem(item + 1)
  })

  const htmlToText = html => {
    var len_text = 0
    var textEnd = false
    const len_max = 10000
    var text = parse(html.slice(0), {
      replace: domNode => {
        if (domNode.children && len_text <= len_max) {
          for (let i = 0; i < domNode.children.length; i++) {
            if (domNode.children[i].type === 'text') {
              len_text += domNode.children[i].data.length
              if (len_text > len_max) {
                textEnd = true
                return <p>{domNode.children[i].data.slice(0, domNode.children[i].data.length - (len_text - len_max))}... (Voir la suite sur {news[item].web_url})</p>
              }
            }
          }
        } else if (domNode.children && len_text > len_max) {
          if (textEnd)
            return <></>
          else {
            textEnd = true
            return <p>... (Voir la suite sur {news[item].web_url})</p>
          }
        }
        if (domNode.name === 'img') {
          return <></>;
        }
        if (domNode.name === 'span') {
          return (
            <span >
              {domToReact(domNode.children, domNode.options)}
            </span>
          );
        }
      }
    })
    return text
  }

  const dateFormat = (date) => {
    var d = new Date(date)
    var day = ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi']
    return day[d.getDay()] + ' ' + ("0" + (d.getDate())).slice(-2) + '/' + ("0" + (d.getMonth() + 1)).slice(-2) + '/' + d.getFullYear()
  }

  const getFstImg = html => {
    if (!html)
      return
    var ele = document.createElement("div");
    ele.innerHTML = html;
    var image = ele.querySelector("img");
    if (!image)
      return <img alt="default" src={NewsImage} style={styles.image} /> // a remplacer
    var img = parse(image.outerHTML, {
      replace: domNode => {
        if (domNode.attribs && domNode.attribs.style) {
          return <img alt="img" src={domNode.attribs.src} style={styles.image} />;
        }
      }
    })
    if (!img || !img.props || !img.props.src) //todo maybe change that
      return <img alt="default" src={NewsImage} style={styles.image} />
    return <img alt="img" src={img.props.src} style={styles.image} />
  }

  const memoImg = useMemo(
    () => {
      if (!news)
        return
      if (!news.length)
        return <img alt="default" src={NewsImage} style={styles.image} />
      return getFstImg(news[item].content)
    },
    [news, item]
  )

  const memoText = useMemo(
    () => {
      if (!news || !news.length)
        return
      return htmlToText(news[item].content)
    },
    [news, item]
  )

  const navDot = (n) => {
    if (news.length <= 1)
      return null
    if (n === item) {
      return <Box key={n + 0.1} borderRadius="50%" {...styles.selectionnedDot} />
    }
    return <Box key={n} borderRadius="50%" {...styles.propsDot} />
  }

  return (
    <div style={{ height: '90%' }}>
      {!news ? <CircularProgress style={{ width: 80, height: 80, marginTop: '5%', marginLeft: '50%' }} /> :
        <div>
          <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
            <div style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
              <Box borderRadius="50%" {...styles.propsBox}>{memoImg}</Box>
              {news.length ? <div style={styles.publishUser}>
                Publié par :
                <div>
                  {news[item].user?.lastName}{' '}{news[item].user?.firstName}
                </div>
              </div> : null}
              {news.length ? <div style={styles.publishAt}>
                Publié le :
                <div >
                  {dateFormat(news[item].created_at)}
                </div>
              </div> : null}
              <div style={{ display: 'flex', flexWrap: 'wrap', marginTop: '12%', marginLeft: '7%', width: '35%', justifyContent: 'center' }}>
                {news.map((it) => navDot(news.indexOf(it)))}
              </div>
            </div>
            <div style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
              <div style={styles.title}>
                {news.length ? news[item].title : 'Actualités'}
              </div>
              {news.length ? null : <div style={styles.title2}>Aucun élément à afficher</div>}
              <div id="content-fade" style={styles.content}>
                {memoText}
              </div>
              {news.length ?
                <div style={styles.subContent}>
                  Plus d'information sur {news[item].web_url}
                </div> : null}
            </div>
          </div>
        </div>
      }
    </div>
  )
}

export default News