import React, { useState } from 'react'
import { BrowserRouter as Router, Route, Redirect } from 'react-router-dom'
import './App.css';
import Main from './components/Main'
import Reset from './components/Reset'
import Connection from './components/Connection'

const App = () => {
  const [connected, setConnected] = useState((!localStorage.getItem("siteUuid") ? false : true))

  const CronJob = require('cron').CronJob;
  const job = new CronJob('0 0 4 * * *', function () {
    console.log('auto reload at 4am');
    window.location.reload(false);
  }, null, true, 'Europe/Paris');
  job.start();

  if (!connected){
    return <Connection setConnected={setConnected} />
  }else{
    return (
    <div className="wrapper">
      <Router>
        <Route key='main' path='/' component={Main} />
        <Route key='main' path='/reset'>
          <Reset setConnected={setConnected} />
        </Route>
        <Redirect from="/" to="/" />
      </Router>
    </div>)
  }
}

export default App;
