import React, { useEffect } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';

const Reset = ({setConnected}) => {
  useEffect(() => {
    localStorage.removeItem("siteUuid")
    setConnected(false)
  }, [])

  return (
    <div style={{ height: '90%' }}>
      <CircularProgress style={{ width: 80, height: 80, marginTop: '5%', marginLeft: '50%' }} />
    </div>
  )
}

export default Reset;