import React, { useState, useEffect, useMemo } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import Box from '@material-ui/core/Box';
import moment from 'moment'
import localization from "moment/locale/fr";

import EventImage from '../assets/image/NEW_Evenements.jpg';

//0 si weeks_availability commence par lundi, sinon 1 (dimanche)
const MONDAY_FIRST = 1
moment.updateLocale("fr", localization);

const styles = {
  title: {
    color: '#373989',
    fontSize: 60,
    fontFamily: 'FuturaLT',
    marginLeft: '-45%',
    marginTop: '10%',
  },
  title2: {
    color: '#373989',
    fontSize: 45,
    fontFamily: 'FuturaLT',
    marginLeft: '-45%',
    marginTop: '5%',
  },
  content: {
    whiteSpace: "pre-wrap",
    color: '#373989',
    fontSize: 32,
    fontFamily: 'FuturaLT',
    marginLeft: '-45%',
    marginTop: '3%',
    width: '130%',
    height: '26.5vh',
    // backgroundColor: 'red',
    overflow: 'hidden',
    textOverflow: 'ellipsis'

  },
  subContent: {
    color: '#373989',
    fontSize: 32,
    fontFamily: 'FuturaLT',
    marginLeft: '-45%',
    marginTop: '4%',
    justifyContent: 'flex-end',
  },
  infoSchedule: {
    backgroundColor: '#3e407e',
    color: '#fff',
    fontSize: 32,
    fontFamily: 'FuturaLT',
    marginLeft: '-45%',
    marginTop: '3%',
    paddingTop: '12px',
    paddingBottom: '12px',
    paddingLeft: '30px',
    paddingRight: '30px',
    width: '130%'
  },
  image: {
    width: '100%',
    height: '100%',
    borderRadius: '50%',
  },
  publishUser: {
    color: '#373989',
    fontSize: 27,
    fontFamily: 'FuturaLT',
    marginLeft: '11%',
    marginTop: '15%'
  },
  publishAt: {
    color: '#373989',
    fontSize: 27,
    fontFamily: 'FuturaLT',
    marginLeft: '11%',
    marginTop: '5%'
  },
  propsBox: {
    borderColor: '#494b94',
    m: 1,
    border: 8,
    style: { width: '28vw', height: '28vw', margin: '-9%' },
  },
  propsDot: {
    bgcolor: '#c5cdff',
    m: 1,
    border: 0,
    style: { width: '1.3vw', height: '1.3vw', marginLeft: '2vw' },
  },
  selectionnedDot: {
    bgcolor: '#ff5f63',
    m: 1,
    border: 0,
    style: { width: '1.3vw', height: '1.3vw', marginLeft: '2vw' },
  },
  propsBoxAvailable: {
    bgcolor: '#f6f6f6',
    borderColor: '#ebbdcc',
    m: 0,
    border: 2,
    style: { width: 650, height: 700, marginTop: '10%' },
  },
}

const Event = ({ data, getProgress }) => {
  const event = data;
  const [item, setItem] = useState(0)

  useEffect(() => {
    if (!event || !event.length)
      return
    if (getProgress() / (100 / event.length) >= item + 1 && item + 1 < event.length)
      setItem(item + 1)
  })

  const dateFormat = (date) => {
    const d = new Date(date)
    const day = ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi']
    return day[d.getDay()] + ' ' + ("0" + (d.getDate())).slice(-2) + '/' + ("0" + (d.getMonth() + 1)).slice(-2) + '/' + d.getFullYear()
  }

  const memoImg = useMemo(
    () => {
      if (!event)
        return
      if (event.length && event[item].url)
        return <img alt="img" src={event[item].url} style={styles.image} />
      return <img alt="default" src={EventImage} style={styles.image} />
    },
    [event, item]
  )
  
  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  const parseInfo = (e) => {
    const d = ['Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi', 'Dimanche']
    let begin = moment.utc(e.date_begin)
    let end = moment.utc(e.date_end)
    console.log(e.date_begin, e.date_end)
    begin = moment.utc(begin.valueOf() - (begin.hour() * 3600 - begin.minute() * 60 - begin.second()) * 1000)
    end = moment.utc(end.valueOf() - (end.hour() * 3600 - end.minute() * 60 - end.second()) * 1000)

    let daysOfWeek = []
    for (let l = 0; l < e.days_recurrence.length; l++) {
      if (e.days_recurrence[(l < e.days_recurrence.length) ? l : 0] === '1'){
        daysOfWeek.push(d[l])
      }
    }
    if (daysOfWeek.length > 1){
      daysOfWeek = daysOfWeek.slice(0, -1).join(', ') + ' et ' + daysOfWeek.slice(-1)
    }else if (daysOfWeek.length > 0){
      daysOfWeek = daysOfWeek[0]
    }else{
      daysOfWeek = ''
    }
    const periodMillisec = end.valueOf() - begin.valueOf()
    const oneWeekMillisec = 7 * 24 * 3600 * 1000
    if (daysOfWeek != '' && periodMillisec > oneWeekMillisec){
      daysOfWeek = `Tous les ${daysOfWeek}`
    }
    let dateString = ''
    if (begin.format('L') === end.format('L')){
      dateString = 'le ' + begin.format('L')
    }else{
      dateString = 'du ' + begin.format('L') + ' au ' + end.format('L')
    }
    let timeString = ''
    if (!e.is_all_day_long){
      timeString += ' de ' + e.time_begin.slice(0, 5) + ' à ' + e.time_end.slice(0, 5)
    }
    let finalString = ''
    if (daysOfWeek != ''){
      finalString = `${daysOfWeek}, `
    }
    finalString = `${finalString}${dateString}`
    if (timeString != ''){
      finalString = `${finalString}${timeString}`
    }
    return capitalizeFirstLetter(finalString)
  }

  const navDot = (n) => {
    if (event.length <= 1)
    return null
    if (n === item) {
      return <Box key={n + 0.1} borderRadius="50%" {...styles.selectionnedDot} />
    }
    return <Box key={n} borderRadius="50%" {...styles.propsDot} />
  }

  const sliceText = (text) => {
    let len_max = 10000 // 250
    if (text.length >= len_max)
      return text.slice(0, len_max) + '... (Voir la suite sur ' + event[item].web_url + ')'
    return text
  }

  return (
    <div style={{ height: '90%' }}>
      {!event ? <CircularProgress style={{ width: 80, height: 80, marginTop: '5%', marginLeft: '50%' }} /> :
        <div>
          <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
            <div style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
              <Box borderRadius="50%" {...styles.propsBox}>{memoImg}</Box>
              {event.length ? <div>
                <div style={styles.publishUser}>
                  Publié par :
                <div>
                    {event[item].user?.lastName}{' '}{event[item].user?.firstName}
                  </div>
                </div>
                <div style={styles.publishAt}>
                  Publié le :
                <div >
                    {dateFormat(event[item].created_at)}
                  </div>
                </div>
                <div style={{display: 'flex', flexWrap: 'wrap', marginTop: '12%', marginLeft: '7%', width: '35%', justifyContent: 'center' }}>
                  {event.map((it) => navDot(event.indexOf(it)))}
                </div>
              </div> : null}
            </div>
            <div style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
              <div style={styles.title}>
                Évènements
              </div>
              {event.length ? null : <div style={styles.title2}>Aucun élément à afficher</div>}
              {/* <div style={{ marginLeft: '-50%', display: "grid", gridTemplateColumns: "repeat(2, 1fr)", gridGap: 140 }}>
                <div>
                  <Box {...styles.propsBoxAvailable}>
                  </Box>
                </div>
                <div> */}
              {event.length ? <div>
                <div style={styles.title2}>
                  {event[item].title}
                </div>
                <div style={styles.infoSchedule}>
                  {parseInfo(event[item])}
                </div>
                <div id='content-fade' style={styles.content}>
                  {sliceText(event[item].description)}
                </div>
                <div style={styles.subContent}>
                  Plus d'informations sur {event[item].web_url}
                  </div>
              </div> : null}
            </div>
          </div>
        </div>
      }
    </div>
  )
}

export default Event