import React, { useState, useEffect, useMemo } from 'react';
import { Container, Col } from 'react-bootstrap';
// import SwiperCore, { EffectFade, Autoplay } from 'swiper';
// import { Swiper, SwiperSlide } from 'swiper/react';
import LinearProgress from '@material-ui/core/LinearProgress';
import { makeStyles, createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import axios from 'axios'
import CircularProgress from '@material-ui/core/CircularProgress';
import Booking from './Booking'
import Event from './Event'
import News from './News'
import Offer from './Offer'
import Meteo from './Meteo'
import moment from 'moment';

// import 'swiper/swiper-bundle.css';
// import 'swiper/components/effect-fade/effect-fade.scss';

const NEWS_SLIDE_ID = 0
const BOOKING_SLIDE_ID = 1
const CLASSIFIED_SLIDE_ID = 2
const EVENT_SLIDE_ID = 3
const METEO_SLIDE_ID = 4

let styles = {
  selectionedItem: {
    backgroundColor: '#3e407e',
    height: 85,
    width: '20%',
    left: 0,
    display: 'inline-block',
    color: '#fff',
    fontSize: 30,
    fontFamily: 'FuturaLT',
  },
  item: {
    backgroundColor: '#5053a3',
    height: 85,
    width: '20%',
    left: 0,
    display: 'inline-block',
    color: '#fff',
    fontSize: 30,
    fontFamily: 'FuturaLT',
  },
  navbarItem: {
    // marginTop: 18,
    height: '95%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }
}

const useStyles = makeStyles({
  progress: {
    backgroundColor: '#f6f6f6',
    height: 6
  },
});

const theme = createMuiTheme({
  palette: {
    secondary: {
      main: '#ea3b76'
    }
  }
})


const Navbar = ({ item, getTime, getProgress, setProgress, changeSlide }) => {
  const classes = useStyles();
  var [selectedItem, setSelectedItem] = useState(item())
  // const [selectedItem, setSelectedItem] = useState(item)
  // const [progress, setProgress] = useState(item - item);
  const interval = 200
  var [diffProgress, setDiffProgress] = useState(100 / (getTime() / interval))
  const [progressPast, setProgressPast] = useState(0)

  useEffect(() => {
    // console.log('nav', getTime(), selectedItem)
    const timer = setInterval(() => {
      setProgress(p => {
        if (p >= 100) {
          // console.log(progressPast, diffProgress)
          if (progressPast >= 2) {
            setProgressPast(0)
            changeSlide()
          } else
            setProgressPast(progressPast + diffProgress)
        }
        if (p < 0)
          return p + 1
        return Math.min(p + diffProgress, 100)
      });
    }, interval);
    return () => {
      clearInterval(timer);
    };
  }, [selectedItem, progressPast]);

  useEffect(() => {
    // console.log(selectedItem, getTime(), diffProgress)
    setSelectedItem(item())
    setDiffProgress(100 / (getTime() / interval))
  }, [item()])

  return (
    <div style={{ position: 'absolute', bottom: 0, width: '100%', backgroundColor: '#f6f6f6' }}>
      <MuiThemeProvider theme={theme}>
        <LinearProgress className={classes.progress} color="secondary" variant="determinate" value={getProgress()} />
      </MuiThemeProvider>
      <Container fluid>
        <Col style={(selectedItem === NEWS_SLIDE_ID) ? styles.selectionedItem : styles.item}>
          <div style={styles.navbarItem} >
            {(selectedItem === NEWS_SLIDE_ID) ? '• ' : ' '}{' '}Actualités
        </div>
        </Col>
        <Col style={(selectedItem === BOOKING_SLIDE_ID) ? styles.selectionedItem : styles.item}>
          <div style={styles.navbarItem} >
            {(selectedItem === BOOKING_SLIDE_ID) ? '• ' : ' '}{' '}Réservations
        </div>
        </Col>
        <Col style={(selectedItem === CLASSIFIED_SLIDE_ID) ? styles.selectionedItem : styles.item}>
          <div style={styles.navbarItem} >
            {(selectedItem === CLASSIFIED_SLIDE_ID) ? '• ' : ' '}{' '}Petites annonces
        </div>
        </Col>
        <Col style={(selectedItem === EVENT_SLIDE_ID) ? styles.selectionedItem : styles.item}>
          <div style={styles.navbarItem} >
            {(selectedItem === EVENT_SLIDE_ID) ? '• ' : ' '}{' '}Evénements
        </div>
        </Col>
        <Col style={(selectedItem === METEO_SLIDE_ID) ? styles.selectionedItem : styles.item}>
          <div style={styles.navbarItem} >
            {(selectedItem === METEO_SLIDE_ID) ? '• ' : ' '}{' '}Météo
        </div>
        </Col>
      </Container>
    </div>
  );
}

const Loading = () => {
  return (
    <div style={{ height: '90%' }}>
      <CircularProgress style={{ width: 80, height: 80, marginTop: '5%', marginLeft: '50%' }} />
    </div>
  )
}

// SwiperCore.use([EffectFade, Autoplay]);

const Main = () => {
  const [progress, setProgress] = useState(0);
  const [activeSlide, setActiveSlide] = useState(0);
  const [booking, setBooking] = useState(undefined)
  const [tagBooking, setTagBooking] = useState([])
  const [event, setEvent] = useState(undefined)
  const [news, setNews] = useState(undefined)
  const [offer, setOffer] = useState(undefined)
  const [meteo, setMeteo] = useState(undefined)
  const [timerMeteo, setTimerMeteo] = useState(0)
  const [fstLaunch, setFstLaunch] = useState(true)
  const TIME_PER_SLIDE = 4000

  const getProgress = () => Math.max(progress, 0)
  const getActiveSlide = () => activeSlide
  const getTime = () => getTimeSlide(activeSlide)

  useEffect(() => {
    if (fstLaunch && (booking || event || news || offer || meteo))
      setFstLaunch(false)
  }, [booking, event, news, offer, meteo, fstLaunch])

  const bookingReq = () => {
    console.log('booking req')
    axios.get(process.env.REACT_APP_API_URL + `/ressources`, {
      headers: {
        'Authorization': localStorage.getItem('siteUuid')
      }
    })
      .then(res => {
        setTagBooking(Object.keys(res.data))
        setBooking(res.data)
      }
      ).catch((err) => {
        if (booking === undefined) {
          setTagBooking([])
          setBooking(null)
        }
      })
  }

  const eventReq = () => {
    console.log('event req')
    axios.get(process.env.REACT_APP_API_URL + `/events`, {
      headers: {
        'Authorization': localStorage.getItem('siteUuid')
      }
    })
      .then(res => {
        console.log("event req", res)
        setEvent(res.data)
      }
      ).catch((err) => {
        if (event === undefined)
          setEvent(null)
      })
  }

  const newsReq = () => {
    console.log('news req')
    axios.get(process.env.REACT_APP_API_URL + `/news`, {
      headers: {
        'Authorization': localStorage.getItem('siteUuid')
      }
    })
      .then(res => {
        setNews(res.data)
      }
      ).catch((err) => {
        if (news === undefined)
          setNews(null)
      })
  }

  const offerReq = () => {
    console.log('offer req')
    axios.get(process.env.REACT_APP_API_URL + `/classifieds`, {
      headers: {
        'Authorization': localStorage.getItem('siteUuid')
      }
    })
      .then(res => {
        setOffer(res.data)
      }
      ).catch((err) => {
        if (offer === undefined)
          setOffer(null)
      })
  }

  const meteoReq = () => {
    console.log('meteo req')
    if (!meteo || moment().valueOf() - timerMeteo > 60 * 60 * 1000) {
      axios.get(process.env.REACT_APP_API_URL + `/meteo`, {
        headers: {
          'Authorization': localStorage.getItem('siteUuid')
        }
      })
        .then(res => {
          setTimerMeteo(moment().valueOf())
          setMeteo(res.data)
        }
        ).catch((err) => {
          if (offer === undefined)
            setMeteo(null)
        })
    }
  }

  const getTimeSlide = (id, swipe = false) => {
    if (id === NEWS_SLIDE_ID && activeSlide === NEWS_SLIDE_ID && news) {
      return Math.max(TIME_PER_SLIDE * news.length * ((swipe) ? 1.1 : 1), 5000)
    } else if (id === BOOKING_SLIDE_ID && activeSlide === BOOKING_SLIDE_ID && booking) {
      var n = 0
      for (let x in booking){
        n += booking[x].ressource.length
      }
      // console.log('bb', Math.max(30000 * n * ((swipe) ? 1.1 : 1), 100))
      return Math.max(1000 * n * ((swipe) ? 1.1 : 1), 5000)
    } else if (id === CLASSIFIED_SLIDE_ID && activeSlide === CLASSIFIED_SLIDE_ID && offer) {
      return Math.max(TIME_PER_SLIDE * offer.length * ((swipe) ? 1.1 : 1), 5000)
    } else if (id === EVENT_SLIDE_ID && activeSlide === EVENT_SLIDE_ID && event) {
      return Math.max(TIME_PER_SLIDE * event.length * ((swipe) ? 1.1 : 1), 5000)
    } else if (id === METEO_SLIDE_ID && activeSlide === METEO_SLIDE_ID && meteo) {
      return 5000
    }
    return 3000
  }

  const canDisplay = useMemo(
    () => {
      if (news !== undefined && activeSlide === NEWS_SLIDE_ID)
        return true
      if (booking !== undefined && activeSlide === BOOKING_SLIDE_ID)
        return true
      if (offer !== undefined && activeSlide === CLASSIFIED_SLIDE_ID)
        return true
      if (event !== undefined && activeSlide === EVENT_SLIDE_ID)
        return true
      if (meteo !== undefined && activeSlide === METEO_SLIDE_ID)
        return true
      return false
    },
    [news, offer, booking, event, meteo, activeSlide]
  )

  const changeSlide = () => {
    var nextSlide = (activeSlide === 4) ? 0 : activeSlide + 1
    setActiveSlide(nextSlide)
    setProgress(-1);
    if (nextSlide === NEWS_SLIDE_ID)
      bookingReq()
    if (nextSlide === BOOKING_SLIDE_ID)
      offerReq()
    if (nextSlide === CLASSIFIED_SLIDE_ID)
      eventReq()
    if (nextSlide === EVENT_SLIDE_ID)
      meteoReq()
    if (nextSlide === METEO_SLIDE_ID)
      newsReq()
  }

  useEffect(() => {
    newsReq()
    bookingReq()
  }, [])

  return (
    <div style={{ minHeight: '100vh', backgroundColor: '#f6f6f6' }}>
      {!fstLaunch ?
        <div onClick={changeSlide}>
          {news && activeSlide === NEWS_SLIDE_ID ? <News data={news} getProgress={getProgress} /> : <>{activeSlide !== NEWS_SLIDE_ID ? null : <Loading />}</>}
          {booking && activeSlide === BOOKING_SLIDE_ID ? <Booking data={booking} dataTag={tagBooking} getProgress={getProgress} /> : <>{activeSlide !== BOOKING_SLIDE_ID ? null : <Loading />}</>}
          {offer && activeSlide === CLASSIFIED_SLIDE_ID ? <Offer data={offer} getProgress={getProgress} /> : <>{activeSlide !== CLASSIFIED_SLIDE_ID ? null : <Loading />}</>}
          {event && activeSlide === EVENT_SLIDE_ID ? <Event data={event} getProgress={getProgress} /> : <>{activeSlide !== EVENT_SLIDE_ID ? null : <Loading />}</>}
          {meteo && activeSlide === METEO_SLIDE_ID ? <Meteo data={meteo} getProgress={getProgress} /> : <>{activeSlide !== METEO_SLIDE_ID ? null : <Loading />}</>}
          {!fstLaunch && canDisplay ? <Navbar item={getActiveSlide} getTime={getTime} getProgress={getProgress} setProgress={setProgress} changeSlide={changeSlide} /> : null}
        </div>
        : <Loading />
      }
    </div>
  )
}

export default Main;