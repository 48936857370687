import React, { useState } from 'react';
import TextField from '@material-ui/core/TextField';
import axios from 'axios'

let styles = {
	page: {
		minHeight: '100vh',
		backgroundColor: '#f6f6f6',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center'
	},
	title: {
		color: '#373989',
		fontSize: 100,
		fontFamily: 'FuturaLT',
		marginTop: '7%',
	},
	content: {
		color: '#373989',
		fontSize: 45,
		fontFamily: 'FuturaLT',
		marginTop: '6%',
	},
	input: {
		marginTop: '2.5%',
		width: 500,
	},
	text: {
		color: '#373989',
		fontSize: 35,
		fontFamily: 'FuturaLT',
		textAlign: 'center',
		letterSpacing: 10,
		margin: 5
	},
	helper: {
		fontSize: 28,
		fontFamily: 'FuturaLT',
		width: 800,
		textAlign: 'center',
		marginLeft: '-49%'
	}
}

const Connection = ({ setConnected }) => {
	const [isValid, setIsValid] = useState(true)
	const [helperText, setHelperText] = useState("")
	const [text, setText] = useState("")

	const isNumeric = (str) => {
		for (let l of str) {
			if (l < '0' || l > '9')
				return false
		}
		return true
	}

	const checkPassword = (password) => {
		axios.get(process.env.REACT_APP_API_URL + `/login`, {
			headers: {
				'Authorization': password
			}
		})
			.then(res => {
				localStorage.setItem("siteUuid", res.data.uuid)
				setConnected(true)
			}
			).catch((err) => {
				setHelperText("Le mot de passe est invalide")
				setIsValid(false)
				setText("")
			})
	}

	const updatePassword = (e) => {
		var password = e.target.value
		if (password.length > 8)
			return
		setText(password)
		if (password.length && !isNumeric(password)) {
			setHelperText("Le mot de passe doit contenir uniquement des chiffres")
			setIsValid(false)
		} else {
			if (!isValid)
				setIsValid(true)
			if (password.length === 8) {
				console.log(password)
				checkPassword(password)
			}
		}
	}

	return (
		<div style={styles.page}>
			<div style={styles.title}>
				Oubaccess Display
			</div>
			<div style={styles.content}>
				Entrer le mot de passe Oubaccess Display de votre résidence
			</div>
			<TextField
				value={text}
				error={!isValid}
				helperText={isValid ? "" : helperText}
				style={styles.input}
				margin="normal"
				inputProps={{ style: styles.text }}
				FormHelperTextProps={{ style: styles.helper }}
				id="password"
				autoFocus={true}
				onChange={updatePassword}
				variant="outlined" />
		</div>
	)
}

export default Connection;