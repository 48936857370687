import React, { useState, useEffect, useMemo } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import moment from 'moment'
import localization from "moment/locale/fr";

import BedroomImage from '../assets/image/NEW_Chambre.jpg';
import CoworkingImage from '../assets/image/NEW_Coworking.jpg';
import ToolImage from '../assets/image/NEW_Outils.jpg';
import CarImage from '../assets/image/NEW_Parking.jpg';
import SportImage from '../assets/image/NEW_Salle_Sport.jpg';
import DefaultImage from '../assets/image/NEW_welcome_2.jpg'

moment.updateLocale("fr", localization);

let styles = {
  title: {
    color: '#373989',
    fontSize: 60,
    fontFamily: 'FuturaLT',
    marginLeft: '-45%',
    marginTop: '10%',
  },
  title2: {
    color: '#373989',
    fontSize: 45,
    fontFamily: 'FuturaLT',
    marginLeft: '-45%',
    marginTop: '9%',
  },
  content: {
    color: '#373989',
    fontSize: 35,
    fontFamily: 'FuturaLT',
    marginLeft: '-45%',
    marginTop: '6%',
    width: '131%'
  },
  content2: {
    color: '#373989',
    fontSize: 35,
    fontFamily: 'FuturaLT',
    marginLeft: '-45%',
    marginTop: '10%',
    width: '131%'
  },
  schedule: {
    color: '#373989',
    fontSize: 35,
    fontFamily: 'FuturaLT',
    marginLeft: '-45%',
    marginTop: '4%',
    width: '131%'
  },
  publishUser: {
    color: '#f6f6f6',
    fontSize: 27,
    fontFamily: 'FuturaLT',
    marginLeft: '11%',
    marginTop: '15%'
  },
  publishAt: {
    color: '#f6f6f6',
    fontSize: 27,
    fontFamily: 'FuturaLT',
    marginLeft: '11%',
    marginTop: '5%'
  },
  image: {
    width: '100%',
    height: '100%',
    borderRadius: '50%',
  },
  propsBox: {
    borderColor: '#494b94',
    m: 1,
    border: 8,
    style: { width: '28vw', height: '28vw', margin: '-9%' },
  },
  propsBoxAvailable: {
    bgcolor: '#3e407e',
    m: 0,
    border: 0,
    style: { width: 450, height: 90 },
  },
  propsBoxTaken: {
    bgcolor: '#e56a43',
    m: 0,
    border: 0,
    style: { width: 450, height: 90 },
  },
  propsBoxDisable: {
    bgcolor: '#e56a43',
    m: 0,
    border: 0,
    style: { width: 350, height: 90 },
  },
  propsBoxScheduleAvailable: {
    bgcolor: '#b9da85',
    m: 0.6,
    border: 0,
    style: { width: 140, height: 80 },
  },
  propsBoxScheduleTaken: {
    bgcolor: '#e56a43',
    m: 0.6,
    border: 0,
    style: { width: 140, height: 80 },
  },
  propsBoxSchedulePlanning: {
    bgcolor: '#fff',
    m: 0.6,
    border: 0,
    style: { width: 140, height: 80 },
  },
  propsDot: {
    bgcolor: '#c5cdff',
    m: 1,
    border: 0,
    style: { width: '1.3vw', height: '1.3vw', marginLeft: '2vw' },
  },
  selectionnedDot: {
    bgcolor: '#ff5f63',
    m: 1,
    border: 0,
    style: { width: '1.3vw', height: '1.3vw', marginLeft: '2vw' },
  }
}

const Booking = ({ data, dataTag, getProgress }) => {
  const booking = data;
  const tagBooking = dataTag
  const [item, setItem] = useState(0)

  useEffect(() => {
    if (!booking || !tagBooking.length)
      return
    if (getProgress() / (100 / tagBooking.length) >= item + 1 && item + 1 < tagBooking.length)
      setItem(item + 1)
  })

  const dateToSec = (d) => {
    return ((d.hour() < 0) ? 24 : d.hour()) * 3600 + d.minute() * 60 + d.second()
  }

  const getTagDay = (n) => {
    if (n === 0)
      return 'sun'
    if (n === 1)
      return 'mon'
    if (n === 2)
      return 'tue'
    if (n === 3)
      return 'wed'
    if (n === 4)
      return 'thu'
    if (n === 5)
      return 'fri'
    if (n === 6)
      return 'sat'
  }

  const getNextOpen = (week, i, time = -1) => {
    const w = ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun']
    const idx = (i === 'sun') ? 'mon' : w[w.indexOf(i) + 1];

    if (!week.mon.length && !week.tue.length && !week.wed.length && !week.thu.length && !week.fri.length && !week.sat.length && !week.sun.length)
      return [idx, -4]
    if (time >= 0 && week[idx].length) {
      if (week[idx][0].time < time)
        return [idx, week[idx][0].time]
      else
        return [idx, -1]
    }
    if (week[idx].length)
      return [idx, -1]
    else
      return getNextOpen(week, idx)
  }

  const isAvailableNow = (week, day, time) => {
    let tmp_n = -2;
    const idx = week[day].filter((it) => it.time <= time).length;
    let n = -2;
    for (let i = 0; i < week[day].length; i++) {
      if (i >= idx && !(i + 1 < week[day].length && week[day][i].time === week[day][i + 1].time)) {
        // console.log("\n|||", n, i, time, idx, week[day][0].time)
        if (i && week[day][i].time === week[day][i - 1].time)
          n = tmp_n
        if (n < 0) {
          if (i + 1 < week[day].length)
            return [n, [day, week[day][i].time]]
          else
            return [n, getNextOpen(week, day, time)]
        }
        return [n, [-1, -1]]
      }
      if (n === -2) {
        n = (week[day][i].key === -1) ? 0 : week[day][i].key
        if (i + 1 < week[day].length && week[day][i].time === week[day][i + 1].time)
          tmp_n = Math.max(n, tmp_n)
        if (i && week[day][i].time === week[day][i - 1].time)
          tmp_n = -2
        continue
      }
      if (week[day][i].key === -1) {
        n = (i % 2 === 0) ? 0 : week[day][i].key
      } else if (week[day][i].key === n && n !== 0) {
        n = (n === -1) ? 0 : n - 1
      } else if (week[day][i].key === n + 1 && n >= 0) {
        n += 1
      }
      if (i + 1 < week[day].length && week[day][i].time === week[day][i + 1].time)
        tmp_n = Math.max(n, tmp_n)
      if (i && week[day][i].time === week[day][i - 1].time)
        tmp_n = -2
    }
    if (week[day].length > 1 && day[week[day].length - 1].time === day[week[day].length - 2].time)
      return [tmp_n, [-1, -1]]
    return [n, getNextOpen(week, day, time)]
  }

  const secToTime = (time) => {
    const d = moment.utc(time * 1000);
    // console.log(time, d.getMinutes(), d.getHours(), d)
    return (d.hour()) + "h" + ("0" + d.minute()).slice(-2)
  }

  const getDay = (d) => {
    const w = ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun']
    const week = ['Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi', 'Dimanche']

    return week[w.indexOf(d)]
  }

  const isAvailableRessources = useMemo(
    () => {
      if (!booking || !tagBooking.length)
        return null
      const schedule = booking[tagBooking[item]].total;
      const max = booking[tagBooking[item]].ressource[0].max;
      const day = moment();
      const [n, [d, open_at]] = isAvailableNow(schedule, getTagDay(day.day()), dateToSec(day));
      if (open_at <= -4)
        return (<div style={{display: 'flex',}}/>)
      if (n >= 0) {
        return (
          <div style={{ display: 'flex', }}>
            Occupé par
            <div style={{ marginLeft: 50, marginTop: -14 }}>
              <Grid container spacing={0}>
                {n < max ?
                  <Box {...styles.propsBoxAvailable}>
                    <div style={{ color: '#fff', height: '100%', display: 'flex', justifyContent: "center", alignItems: "center" }}>
                      {n} / {max} personnes
                </div>
                  </Box> :
                  <Box {...styles.propsBoxTaken}>
                    <div style={{ color: '#fff', height: '100%', display: 'flex', justifyContent: "center", alignItems: "center" }}>
                      {n} / {max} personnes
              </div>
                  </Box>
                }
              </Grid>
            </div>
          </div>
        )
      }
      return (
        <div style={{ display: 'flex', }}>
          Ouvert à partir de
          <div style={{ marginLeft: 50, marginTop: -14 }}>
            <Grid container spacing={0}>
              <Box {...styles.propsBoxDisable}>
                <div style={{ color: '#fff', height: '100%', display: 'flex', justifyContent: "center", alignItems: "center" }}>
                  {open_at >= 0 ? secToTime(open_at) : getDay(d)}
                </div>
              </Box>
            </Grid>
          </div>
        </div>
      )
    }, [booking, tagBooking, item]
  )

  const isHourAvailable = (schedule, h, date, max) => {
    let t = ((h > 23) ? h - 24 : h);
    const day = schedule[getTagDay((t < h) ? (date + 1 > 6) ? 0 : date + 1 : date)];
    t = t * 3600
    let tmp_n = -2;
    let n = -2;
    for (let i = 0; i < day.length; i++) {
      // console.log(i, day[i].time, day[i].time >= t + 3599 , i && day[i - 1].time < t , n >= 0 , n < max)
      if (i && day[i].time >= t + 3599 && day[i - 1].time < t && n >= 0 && n < max)
        return 1
      if (n === -2) {
        n = (day[i].key === -1) ? 0 : day[i].key
        if (!(i + 1 < day.length && day[i].time === day[i + 1].time)) {
          if (i && day[i].time === day[i - 1].time && tmp_n >= 0 && tmp_n < max && day[i].time >= t && day[i].time < t + 3599)
            return 1
          else if (!(i && day[i].time === day[i - 1].time) && n >= 0 && n < max && day[i].time >= t && day[i].time < t + 3599)
            return 1
        }
        if (i + 1 < day.length && day[i].time === day[i + 1].time)
          tmp_n = Math.max(n, tmp_n)
        if (i && day[i].time === day[i - 1].time)
          tmp_n = -2
        // console.log(tmp_n, t, h, i, n, 'ss')
        continue
      }
      if (day[i].key === -1) {
        n = (i % 2 === 0) ? 0 : day[i].key
      } else if (day[i].key === n && n !== 0) {
        n = (n === -1) ? 0 : n - 1
      } else if (day[i].key === n + 1 && n >= 0) {
        n += 1
      }
      //
      if (!(i + 1 < day.length && day[i].time === day[i + 1].time)) {
        if (i && day[i].time === day[i - 1].time && tmp_n >= 0 && tmp_n < max && day[i].time >= t && day[i].time < t + 3599)
          return 1
        else if (!(i && day[i].time === day[i - 1].time) && n >= 0 && n < max && day[i].time >= t && day[i].time < t + 3599)
          return 1
      }
      // console.log(tmp_n, t, h, i, n)
      if (i + 1 < day.length && day[i].time === day[i + 1].time)
        tmp_n = Math.max(n, tmp_n)
      if (i && day[i].time === day[i - 1].time)
        tmp_n = -2
    }
    return 0
  }

  const dayAvailability = useMemo(
    () => {
      if (!booking || !tagBooking.length)
        return null
      const schedule = booking[tagBooking[item]].total;
      const max = booking[tagBooking[item]].ressource[0].max;
      const now = moment();
      const h = now.hour();
      const time = [];
      for (let i = 0; i < 7; i++) {
        time.push(isHourAvailable(schedule, h + i, now.day(), max))
      }
      return (
        <Grid container>
          <Grid container width='100%'>
            {time.map((value, it) => (
              <Box key={it} {...value ? styles.propsBoxScheduleAvailable : styles.propsBoxScheduleTaken}>
                <div style={{ color: '#fff', height: '100%', display: "flex", justifyContent: "center", alignItems: "center" }}>
                  {(h + it > 23) ? it - (24 - h) : h + it}h
              </div>
              </Box>
            ))}
          </Grid>
        </Grid>
      )
    }, [booking, tagBooking, item]
  )

  const isDayAvailable = (day, max) => {
    let tmp_n = -2;
    let n = -2;
    // var idx = day.filter((it) => it.time <= 1).length
    // day = [{ time: 1, key: -1 }, { time: 1, key: -1 }]
    // day = [{ time: 1, key: -1 }, { time: 1, key: 1 }, { time: 1, key: 2 }, { time: 4, key: 2 }, { time: 5, key: 1 }, { time: 10, key: -1 }]
    for (let i = 0; i < day.length; i++) {
      if (n === -2) {
        n = (day[i].key === -1) ? 0 : day[i].key
        if (!(i + 1 < day.length && day[i].time === day[i + 1].time)) {
          if (i && day[i].time === day[i - 1].time && tmp_n >= 0 && tmp_n < max)
            return true
          else if (!(i && day[i].time === day[i - 1].time) && n >= 0 && n < max)
            return true
        }
        if (i + 1 < day.length && day[i].time === day[i + 1].time)
          tmp_n = Math.max(n, tmp_n)
        if (i && day[i].time === day[i - 1].time)
          tmp_n = -2
        // console.log(n, i, idx, 'start')
        continue
      }
      if (day[i].key === -1) {
        n = (i % 2 === 0) ? 0 : day[i].key
      } else if (day[i].key === n && n !== 0) {
        n = (n === -1) ? 0 : n - 1
      } else if (day[i].key === n + 1 && n >= 0) {
        n += 1
      }
      if (!(i + 1 < day.length && day[i].time === day[i + 1].time)) {
        if (i && day[i].time === day[i - 1].time && tmp_n >= 0 && tmp_n < max)
          return true
        else if (!(i && day[i].time === day[i - 1].time) && n >= 0 && n < max)
          return true
      }
      if (i + 1 < day.length && day[i].time === day[i + 1].time)
        tmp_n = Math.max(n, tmp_n)
      if (i && day[i].time === day[i - 1].time)
        tmp_n = -2
      // console.log(n, i, idx)
    }
    return false
  }

  const isWeekAvailable = (schedule, max) => {
    const time = [];
    for (let x in schedule) {
      //   if (x === 'wed') {
      //     time.push(0)
      //     continue
      // }
      if (schedule[x].length && isDayAvailable(schedule[x], max)) {
        time.push(1)
      } else {
        time.push(0)
      }
    }
    return time
  }

  const weekAvailability = useMemo(
    () => {
      if (!booking || !tagBooking.length || booking[tagBooking[item]].ressource.length >= 2)
        return null
      const schedule = booking[tagBooking[item]].total;
      const max = booking[tagBooking[item]].ressource[0].max;
      const time = isWeekAvailable(schedule, max);
      const week = ['L', 'M', 'M', 'J', 'V', 'S', 'D'];
      return (
        <Grid container>
          <Grid container width='100%'>
            {time.map((value, it) => (
              <Box key={it} {...value ? styles.propsBoxScheduleAvailable : styles.propsBoxScheduleTaken}>
                <div style={{ color: '#fff', height: '100%', display: "flex", justifyContent: "center", alignItems: "center" }}>
                  {week[it]}
                </div>
              </Box>
            ))}
          </Grid>
        </Grid>
      )
    }, [booking, tagBooking, item]
  )

  const cutStr = (s, n) => {
    if (s.length > n)
      return s.slice(0, n) + '...'
    return s
  }

  const planningAvailability = useMemo(
    () => {
      if (!booking || !tagBooking.length || booking[tagBooking[item]].ressource.length < 2)
        return null
      const ressource = booking[tagBooking[item]].ressource;
      const max = 100 / tagBooking.length;
      // var max = (time / tagBooking.length) / interval
      const week = ['L', 'M', 'M', 'J', 'V', 'S', 'D'];
      const t = [];
      // if (ressource.length < 5) {
      //   for (let i = 0; i < 5; i++)
      //     ressource.push(ressource[0])
      // }
      for (let i = 0; i < ressource.length; i++) {
        t.push(isWeekAvailable(ressource[i].week, ressource[i].max))
      }
      // var idx = Math.floor(counter / (max / (Math.ceil(t.length / 4))))
      let idx = Math.floor((getProgress() % max) / (max / Math.ceil(t.length / 4)));
      const time_week = [];
      const r = [];
      for (let i = 0; i < t.length; i += 4) {
        time_week.push(t.slice(i, i + 4));
        r.push(ressource.slice(i, i + 4))
      }
      if ((item + 1) * max <= getProgress())
        idx = r.length - 1
      idx = (idx >= r.length) ? r.length - 1 : idx
      // console.log(idx, max, getProgress(), r.length)
      return (
        <div>
          <Grid container>
            <Grid container width='100%' style={{ marginBottom: '2%' }}>
              {week.map((value, it) => (
                <Box key={it} {...styles.propsBoxSchedulePlanning}>
                  <div style={{ color: '#373989', height: '100%', display: "flex", justifyContent: "center", alignItems: "center" }}>
                    {value}
                  </div>
                </Box>
              ))}
            </Grid>
          </Grid>
          <div style={{ flex: "1", width: '130%', marginLeft: '-35%' }}>
            <Grid container style={{ display: "flex", flexDirection: "row", justifyContent: "flex-end" }}>
              {r[idx].map((val, x) => (
                <Grid container>
                  <div style={{ fontSize: 35, marginRight: '2%', width: '21vw', height: 90, display: "flex", justifyContent: "flex-end", alignItems: "center", overflow: 'hidden' }}>
                    {cutStr(val.title, 20)}
                  </div>
                  {week.map((value, it) => (
                    <Box key={it} {...time_week[idx][x][it] ? styles.propsBoxScheduleAvailable : styles.propsBoxScheduleTaken}>
                    </Box>
                  ))}
                </Grid>
              ))}
            </Grid>
          </div>
        </div>
      )
    }, [booking, tagBooking, item, getProgress()]
  )

  const memoImg = useMemo(
    () => {
      if (!tagBooking.length)
        return <img alt="default" src={DefaultImage} style={styles.image} />
      if (tagBooking[item] === 'BEDROOM')
        return <img alt="default" src={BedroomImage} style={styles.image} />
      if (tagBooking[item] === 'MEETING')
        return <img alt="default" src={CoworkingImage} style={styles.image} />
      if (tagBooking[item] === 'CAR')
        return <img alt="default" src={CarImage} style={styles.image} />
      if (tagBooking[item] === 'SPORT')
        return <img alt="default" src={SportImage} style={styles.image} />
      if (tagBooking[item] === 'TOOL')
        return <img alt="default" src={ToolImage} style={styles.image} />
      return <img alt="default" src={DefaultImage} style={styles.image} />
    },
    [tagBooking, item]
  )

  const navDot = (n) => {
    if (tagBooking.length <= 1)
      return null
    if (n === item) {
      return <Box key={n + 0.1} borderRadius="50%" {...styles.selectionnedDot} />
    }
    return <Box key={n} borderRadius="50%" {...styles.propsDot} />
  }

  //tmp booking[tagBooking[item]].ressource.length < 2 a remettre 2 fois en bas !!!!!

  return (
    <div style={{ height: '90%' }}>
      {!booking ? <CircularProgress style={{ width: 80, height: 80, marginTop: '5%', marginLeft: '50%' }} /> :
        <div>
          <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
            <div style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
              <Box borderRadius="50%" {...styles.propsBox}>{memoImg}</Box>
              <div style={styles.publishUser}>
                Publié par :
                <div>
                  pro
                </div>
              </div>
              <div style={styles.publishAt}>
                Publié le :
                <div >
                  css
                </div>
              </div>
              <div style={{ display: 'flex', flexWrap: 'wrap', marginTop: '12%', marginLeft: '7%', width: '35%', justifyContent: 'center' }}>
                {tagBooking.map((it) => navDot(tagBooking.indexOf(it)))}
              </div>
            </div>
            <div style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
              <div style={styles.title}>
                {tagBooking.length ? booking[tagBooking[item]].title : 'Réservations'}
              </div>
              {tagBooking.length ? null : <div style={styles.title2}>Aucun élément à afficher</div>}
              {tagBooking.length && booking[tagBooking[item]].ressource.length < 2 ?
                <>
                  <div style={styles.content}>
                    {isAvailableRessources}
                  </div>
                  <div style={styles.content2}>
                    Disponibilité de la journée :
                  </div>
                  <div style={styles.schedule}>
                    {dayAvailability}
                  </div>
                </> : null}
              {tagBooking.length ? <div style={styles.content}>
                Disponibilité de la semaine :
              </div> : null}
              {tagBooking.length ? <div style={styles.schedule}>
                {booking[tagBooking[item]].ressource.length < 2 ?
                  weekAvailability : planningAvailability}
              </div> : null}
            </div>
          </div>
        </div>
      }
    </div>
  )
}

export default Booking