import React, { useState, useEffect, useMemo } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';

import OfferImage from '../assets/image/NEW_Annonces.jpg';

const styles = {
  title: {
    color: '#373989',
    fontSize: 60,
    fontFamily: 'FuturaLT',
    marginLeft: '-45%',
    marginTop: '10%',
  },
  title2: {
    color: '#373989',
    fontSize: 45,
    fontFamily: 'FuturaLT',
    marginLeft: '-45%',
    marginTop: '5%',
  },
  content: {
    whiteSpace: 'pre-wrap',
    color: '#373989',
    fontSize: 32,
    fontFamily: 'FuturaLT',
    marginLeft: '-45%',
    marginTop: '4%',
    marginBottom: '3.9%',
    width: '130%',
    height: '33vh',
    // backgroundColor: 'red',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  subContent: {
    color: '#373989',
    fontSize: 32,
    fontFamily: 'FuturaLT',
    marginLeft: '-45%',
    marginTop: '4%',
    justifyContent: 'flex-end',
  },
  tag: {
    color: '#373989',
    fontSize: 40,
    fontFamily: 'FuturaLT',
    marginLeft: '-45%',
    justifyContent: 'flex-end',
  },
  schedule: {
    color: '#373989',
    fontSize: 40,
    fontFamily: 'FuturaLT',
    marginLeft: '-45%',
    marginTop: '4%',
    width: '131%'
  },
  publishUser: {
    color: '#373989',
    fontSize: 27,
    fontFamily: 'FuturaLT',
    marginLeft: '11%',
    marginTop: '15%'
  },
  publishAt: {
    color: '#373989',
    fontSize: 27,
    fontFamily: 'FuturaLT',
    marginLeft: '11%',
    marginTop: '5%'
  },
  image: {
    width: '100%',
    height: '100%',
    borderRadius: '50%',
  },
  propsBox: {
    borderColor: '#494b94',
    m: 1,
    border: 8,
    style: { width: '28vw', height: '28vw', margin: '-9%' },
  },
  propsBoxAvailable: {
    bgcolor: '#f6f6f6',
    borderColor: '#ebbdcc',
    m: 0,
    border: 2,
    style: { width: 500, height: 700, marginTop: '10%' },
  },
  propsBoxDirection: {
    bgcolor: '#f1342d',
    m: 0,
    border: 0,
    style: { marginRight: '2%', paddingLeft: 30, paddingRight: 30, height: 80 },
  },
  propsBoxType: {
    bgcolor: '#74ab3e',
    m: 0,
    border: 0,
    style: { marginRight: '2%', paddingLeft: 30, paddingRight: 30, height: 80 },
  },
  propsBoxCategory: {
    bgcolor: '#fd6730',
    m: 0,
    border: 0,
    style: { paddingLeft: 30, paddingRight: 30, height: 80 },
  },
  propsDot: {
    bgcolor: '#c5cdff',
    m: 1,
    border: 0,
    style: { width: '1.3vw', height: '1.3vw', marginLeft: '2vw' },
  },
  selectionnedDot: {
    bgcolor: '#ff5f63',
    m: 1,
    border: 0,
    style: { width: '1.3vw', height: '1.3vw', marginLeft: '2vw' },
  }
}

const Offer = ({ data, getProgress }) => {
  const [offer] = useState(data)
  const [item, setItem] = useState(0)

  useEffect(() => {
    if (!offer || !offer?.length)
      return
    if (getProgress() / (100 / offer?.length) >= item + 1 && item + 1 < offer?.length)
      setItem(item + 1)
  })

  const memoImg = useMemo(
    () => {
      if (!offer)
        return
      if (offer?.length && offer[item].url)
        return <img alt="img" src={offer[item].url} style={styles.image} />
      return <img alt="default" src={OfferImage} style={styles.image} />
    },
    [offer, item]
  )

  const dateFormat = (date) => {
    var d = new Date(date)
    var day = ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi']
    return day[d.getDay()] + ' ' + ("0" + (d.getDate())).slice(-2) + '/' + ("0" + (d.getMonth() + 1)).slice(-2) + '/' + d.getFullYear()
  }

  const navDot = (n) => {
    if (offer?.length <= 1)
      return null
    if (n === item) {
      return <Box key={n + 0.1} borderRadius="50%" {...styles.selectionnedDot} />
    }
    return <Box key={n} borderRadius="50%" {...styles.propsDot} />
  }

  const sliceText = (text) => {
    let len_max = 10000 //150
    // text += 'lxdkgh dsfhdfikl \ngkldjsfklgjklidfkl gjkldfh gkl  lxdkgh dsfhdfikl gkldjsfklgjklidfkl gjkldfh gkl lxdkgh dsfhdfikl gkldjsfklgjklidfkl gjkldfh gklhdfh lxdkgh dsfhdfikl gkldjsfklgjklidfkl gjkldfh gklhdfhkghksdhfkgdf lkfg lxdkgh dsfhdfikl gkldjsfklgjklidfkl gjkldfh gklhdfhkghksdhfkgdf lkfgdkfghdfki gjkldflgjdfklj gj dfgjdl fjlj fgidflkgkldf jgklj dl' + 'lxdkgh dsfhdfikl gkldjsfklgjklidfkl gjkldfh gkl  lxdkgh dsfhdfikl gkldjsfklgjklidfkl gjkldfh gkl lxdkgh dsfhdfikl gkldjsfklgjklidfkl gjkldfh gklhdfh lxdkgh dsfhdfikl gkldjsfklgjklidfkl gjkldfh gklhdfhkghksdhfkgdf lkfg lxdkgh dsfhdfikl gkldjsfklgjklidfkl gjkldfh gklhdfhkghksdhfkgdf lkfgdkfghdfki gjkldflgjdfklj gj dfgjdl fjlj fgidflkgkldf jgklj dl'
    if (text?.length >= len_max) //150
      return text.slice(0, len_max) + '... (Voir la suite sur ' + offer[item].web_url + ')'
    return text
  }

  return (
    <div style={{ height: '90%' }}>
      {!offer ? <CircularProgress style={{ width: 80, height: 80, marginTop: '5%', marginLeft: '50%' }} /> :
        <div>
          <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
            <div style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
              <Box borderRadius="50%" {...styles.propsBox}>{memoImg}</Box>
              {offer?.length ?
                <div>
                  <div style={styles.publishUser}>
                    Publié par :
                    <div>
                      {offer[item].user?.lastName}{' '}{offer[item].user?.firstName}
                    </div>
                  </div>
                  <div style={styles.publishAt}>
                    Publié le :
                    <div >
                      {dateFormat(offer[item].created_at)}
                    </div>
                  </div>
                  <div style={{display: 'flex', flexWrap: 'wrap', marginTop: '12%', marginLeft: '7%', width: '35%', justifyContent: 'center' }}>
                    {offer.map((it) => navDot(offer.indexOf(it)))}
                  </div>
                </div> : null}
            </div>
            <div style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
              <div style={styles.title}>
                Petites annonces
              </div>
              {/* <div style={{ marginLeft: '-50%', display: "grid", gridTemplateColumns: "repeat(2, 1fr)", gridGap: 140 }}>
                <div>
                  <Box {...styles.propsBoxAvailable}>
                  </Box>
                </div> */}
              {/* <div> */}
              <div style={{ flex: 1 }}>
                <div style={styles.title2}>
                  {offer?.length ? offer[item].title : 'Aucun élément à afficher'}
                </div>
                <div id='content-fade' style={styles.content}>
                  {offer?.length ? sliceText(offer[item].description) : null}
                </div>
                {offer?.length ?
                <div style={styles.subContent}>
                  Plus d'information sur {offer[item].web_url}
                  </div> : null}
              </div>
              <div style={styles.tag}>
                {offer?.length ? <Grid container>
                  <Grid container>
                    <Box {...styles.propsBoxDirection}>
                      <div style={{ color: '#fff', height: '100%', display: "flex", justifyContent: "center", alignItems: "center" }}>
                        {offer[item].direction === 'request' ? 'Demande' : 'Offre'}
                      </div>
                    </Box>
                    <Box {...styles.propsBoxType}>
                      <div style={{ color: '#fff', height: '100%', display: "flex", justifyContent: "center", alignItems: "center" }}>
                        {offer[item].category.type === 'services' ? 'Service' : 'Bien'}
                      </div>
                    </Box>
                    <Box {...styles.propsBoxCategory}>
                      <div style={{ color: '#fff', height: '100%', display: "flex", justifyContent: "center", alignItems: "center" }}>
                        {offer[item].category.title}
                      </div>
                    </Box>
                  </Grid>
                </Grid> : null}
              </div>
              {/* </div> */}
              {/* </div> */}
            </div>
          </div>
        </div>
      }
    </div>
  )
}

export default Offer