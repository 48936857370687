import React, { useState, useMemo } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import Box from '@material-ui/core/Box';
import moment from 'moment'
import localization from "moment/locale/fr";
import Grid from '@material-ui/core/Grid';

import MeteoImage from '../assets/image/Section_meteo2.jpg';
import {
  Umbrella,
  Wind,
  ArrowDown,
  ArrowUp,
  Sun,
  CloudRain,
  CloudRainHeavy,
  Snow,
  CloudSun,
  CloudHaze,
  CloudLightningRain,
  Cloudy,
  Clouds,
  MoonFill,
  CloudMoonFill,
  CloudyFill,
  CloudsFill,
  CloudLightningRainFill,
  CloudRainFill,
  CloudRainHeavyFill,
  CloudHaze2Fill
} from 'react-bootstrap-icons'

moment.updateLocale("fr", localization);

let styles = {
  title: {
    color: '#373989',
    fontSize: 60,
    fontFamily: 'FuturaLT',
    marginLeft: '-45%',
    marginTop: '10%',
  },
  temp: {
    color: '#373989',
    fontSize: 150,
    fontFamily: 'FuturaLT',
    marginLeft: '11%',
    marginTop: '15%',
    display: 'flex'
  },
  title2: {
    color: '#373989',
    fontSize: 50,
    fontFamily: 'FuturaLT',
    marginLeft: '-45%',
    marginTop: '5%',
  },
  title3: {
    color: '#373989',
    fontSize: 45,
    fontFamily: 'FuturaLT',
    marginLeft: '-45%',
    marginTop: '2%',
  },
  title4: {
    color: '#373989',
    fontSize: 40,
    fontFamily: 'FuturaLT',
    marginLeft: '-45%',
    marginTop: '2%',
  },
  content: {
    whiteSpace: "pre-wrap",
    color: '#373989',
    fontSize: 35,
    fontFamily: 'FuturaLT',
    // marginLeft: '-45%',
  },
  content2: {
    color: '#373989',
    fontSize: 35,
    fontFamily: 'FuturaLT',
    // marginLeft: '8%',
  },
  content3: {
    color: '#e56a43',
    fontSize: 40,
    fontFamily: 'FuturaLT',
    marginLeft: '-45%',
    marginTop: '4%',
  },
  content4: {
    color: '#373989',
    fontSize: 35,
    fontFamily: 'FuturaLT',
    marginLeft: '-45%',
    // marginTop: '1%'
  },
  image: {
    width: '100%',
    height: '100%',
    borderRadius: '50%',
  },
  icon: {
    width: '70px',
    height: '70px',
    marginTop: '3.4%',
    marginLeft: '2%',
  },
  iconPlanning: {
    width: '60px',
    height: '60px',
    marginTop: '3.4%',
    marginLeft: '2%',
  },
  propsBoxScheduleAvailable: {
    bgcolor: '#f6f6f6',
    m: 0.5,
    border: 3,
    borderColor: '#e8e6eb',
    borderRadius: 17,
    style: { width: 168, height: 220 },
  },
  propsBox: {
    borderColor: '#494b94',
    m: 1,
    border: 8,
    style: { width: '28vw', height: '28vw', margin: '-9%' },
  },
}

const Meteo = ({ data, getProgress }) => {
  const [meteo] = useState(data)

  const memoImg = useMemo(
    () => {
      if (!meteo)
        return
      return <img alt="default" src={MeteoImage} style={styles.image} />
    },
    [meteo]
  )

  const capitalize = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1)
  }

  const get_meteo_icon = (icon, props = {}) => {
    if (icon === "01d")
      return <Sun {...props} />
    if (icon === "01n")
      return <MoonFill {...props} />
    if (icon === "02d")
      return <CloudSun {...props} />
    if (icon === "02n")
      return <CloudMoonFill {...props} />
    if (icon === "03d")
      return <Cloudy {...props} />
    if (icon === "03n")
      return <CloudyFill {...props} />
    if (icon === "04d")
      return <Clouds {...props} />
    if (icon === "04n")
      return <CloudsFill {...props} />
    if (icon === "09d")
      return <CloudRainHeavy {...props} />
    if (icon === "09n")
      return <CloudRainHeavyFill {...props} />
    if (icon === "10d")
      return <CloudRain {...props} />
    if (icon === "10n")
      return <CloudRainFill {...props} />
    if (icon === "11d")
      return <CloudLightningRain {...props} />
    if (icon === "11n")
      return <CloudLightningRainFill {...props} />
    if (icon === "13d" || icon === "13n")
      return <Snow {...props} />
    if (icon === "50d")
      return <CloudHaze {...props} />
    if (icon === "50n")
      return <CloudHaze2Fill {...props} />
    return <Sun {...props} />
  }

//   const meteo_hour = () => {
//     let hour_step = 0
//     if (moment(meteo.meteo.hourly[1].dt * 1000).valueOf() < moment().valueOf())
//       hour_step = 1
//     let hours = meteo.meteo.hourly.slice(1 + hour_step, 8 + hour_step)
// 
//     return (
//       <div style={{ marginLeft: '-45%' }}>
//         <Grid container width='100%' style={{ marginBottom: '2%', marginTop: '1%' }}>
//           {hours.map((h, it) =>
//             <Box key={it} {...styles.propsBoxScheduleAvailable}>
//               <div style={{ color: '#373989', fontSize: 32, display: "flex", justifyContent: "center" }}>
//                 {moment(h.dt * 1000).format('LT')}
//               </div>
//               <div style={{ color: '#373989', marginTop: '4%', marginBottom: '5%', fontSize: 40, display: "flex", justifyContent: "center" }}>
//                 {get_meteo_icon(h.weather[0].icon)}
//                 {/* <img style={styles.iconPlanning} alt={'hour_' + it} src={`http://openweathermap.org/img/wn/${h.weather[0].icon}.png`} /> */}
//               </div>
//               <div style={{ color: '#373989', fontSize: 32, display: "flex", justifyContent: "center" }}>
//                 {Math.round(h.temp) + '°'}
//               </div>
//             </Box>
//           )}
//         </Grid>
//       </div >
//     )
//   }

  const meteo_day = () => {
    let days = meteo.meteo.daily.slice(0, 7)
    return (
      <div style={{ marginLeft: '-45%' }}>
        <Grid container width='100%' style={{ marginBottom: '1%', marginTop: '2%' }}>
          {days.map((d, it) =>
            <Box key={it} {...styles.propsBoxScheduleAvailable}>
              <div style={{ color: '#373989', fontSize: 27, display: "flex", justifyContent: "center", marginTop: '6%' }}>
                {capitalize(moment(d.dt * 1000).format('dddd'))}
              </div>
              <div style={{ color: '#373989', fontSize: 40, marginTop: '6%', marginBottom: '10%', display: "flex", justifyContent: "center" }}>
                {get_meteo_icon(d.weather[0].icon)}
                {/* <img style={styles.iconPlanning} alt={'hour_' + it} src={`http://openweathermap.org/img/wn/${d.weather[0].icon}.png`} /> */}
              </div>
              <div style={{ display: "flex", justifyContent: "center", minWidth: 0, flex: 1 }}>
                <div style={{ color: '#373989', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', fontSize: 20 }}>
                  {capitalize(d.weather[0].description)}
                </div>
              </div>
              <div style={{ color: '#373989', marginTop: '5%', fontSize: 24, display: "flex", justifyContent: "center" }}>
                <ArrowDown color='#e56a43' />
                {Math.round(d.temp.min)}°
                  <ArrowUp color='#b9da85' style={{ marginLeft: '8%' }} />
                {Math.round(d.temp.max)}°
                </div>
            </Box>
          )}
        </Grid>
      </div >
    )
  }

  return (
    <div style={{ height: '90%' }}>
      {!meteo ? <CircularProgress style={{ width: 80, height: 80, marginTop: '5%', marginLeft: '50%' }} /> :
        <div>
          <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
            <div style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
              <Box borderRadius="50%" {...styles.propsBox}>{memoImg}</Box>
              <div style={styles.temp}>
                {Math.round(meteo.meteo.current.temp)}
                <div style={{fontSize: 40, marginTop: '6%', marginLeft: '2.5%'}}>°C</div>
                </div>
            </div>
            <div style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
              <div style={styles.title}>
                Météo
              </div>
              <div style={styles.title2}>
                {capitalize(moment().format('dddd DD MMMM, HH:mm'))}
              </div>
              <div style={styles.content4}>
                {meteo.eph}
              </div>
              {/* <div>
                <div style={styles.title4}>
                  {'Température: ' + Math.round(meteo.meteo.current.temp) + '°C'}
                </div>
              </div> */}
              <div style={{ display: 'flex', flexWrap: 'wrap', marginLeft: '-45%', marginTop: '1%' }}>
                {get_meteo_icon(meteo.meteo.current.weather[0].icon, { color: "#373989", size: 45, style: { marginRight: '1%' } })}
                {/* <div>
                </div> */}
                <div style={styles.content}>
                  {capitalize(meteo.meteo.current.weather[0].description)}
                </div>
                <Wind style={{ marginLeft: '8%', marginRight: '1%' }} color="#373989" size={45} />
                <div style={styles.content2}>
                  {Math.round(meteo.meteo.current.wind_speed * 3.6)}{' '}km/h
                </div>
                <Umbrella style={{ marginLeft: '8%', marginRight: '1%' }} color="#373989" size={45} />
                <div style={styles.content2}>
                  {Math.round(meteo.meteo.hourly[0].pop * 100)}{' '}%
                </div>
              </div>
              {/* <div style={styles.content3}>
                Prévisions horaires
                </div>
              <div>
                {meteo_hour()}
              </div> */}
              <div style={styles.content3}>
                Prévisions de la semaine
                </div>
              <div>
                {meteo_day()}
              </div>
            </div>
          </div>
        </div>
      }
    </div>
  )
}

export default Meteo;